import React from 'react'

// Components
import { BlogConsumer, BlogFilter } from 'components/shared/Blogik'

// Third Party
import styled from 'styled-components'

const StyledBlogFilter = styled(BlogFilter)<{ selected: boolean }>`
  border: 2px solid ${(props) => props.theme.color.dark};
  border-radius: 8px;
  padding: 8px 20px;
  background-color: ${(props) =>
    props.selected ? props.theme.color.dark : props.theme.color.light};
  color: ${(props) =>
    props.selected ? props.theme.color.light : props.theme.color.dark};
  transition: all 0.3s ease;
  white-space: nowrap;
  margin-right: 0.5rem;
  font-size: 14px;

  &:last-of-type {
    margin-right: 0;
  }

  &:hover {
    background-color: ${(props) =>
      props.selected ? props.theme.color.dark : props.theme.color.secondary};
  }

  @media screen and (max-width: 991px) {
    font-size: 12px;
    padding: 8px 15px;
  }

  @media screen and (max-width: 768px) {
    font-size: 14px;
    margin-right: 0;
  }
`

interface BlogFiltersProps {}

interface BlogFilterProps {
  // eslint-disable-next-line
  node: GatsbyTypes.WpVacature_type
}

const VacatureFilters: React.FC<BlogFiltersProps> = () => (
  <BlogConsumer>
    {({ unfilteredPosts, categories, isSelected }: any) => (
      <>
        <div className="pb-3 font-weight-bold font-size-20">
          Filter op categorie
        </div>
        <div className="d-flex flex-wrap justify-content-start">
          {categories.map((category: BlogFilterProps) => (
            <StyledBlogFilter
              key={category.node.id}
              id={category.node.id}
              selected={isSelected(category.node.id)}
              className="mb-2"
            >
              {`${category.node.name} (${
                unfilteredPosts.filter((up: any) =>
                  up.node.vacatureTypes.nodes.find(
                    (v: any) => v.id === category.node.id
                  )
                ).length
              })`}
            </StyledBlogFilter>
          ))}
        </div>
      </>
    )}
  </BlogConsumer>
)

export default VacatureFilters
