import React from 'react'

// Components
import MoreInfo from 'components/flex/CTA/MoreInfo'

interface CTAShellProps {
  fields: any
  location?: any
}

interface CTASProps {
  [key: string]: any
}

const CTAShell: React.FC<CTAShellProps> = ({ fields, location = {} }) => {
  const ctas: CTASProps = {
    more_info: MoreInfo,
  }

  if (!fields.styleType || !ctas[fields.styleType]) {
    return null
  }

  const Component = ctas[fields.styleType]

  return (
    <Component
      key={`${location ? location.pathname : 'flex'}`}
      fields={fields}
      location={location}
    />
  )
}

export default CTAShell
