import React from 'react'

// Components
import SecondaryPreview from 'components/elements/Story/SecondaryPreview'

interface AllStoriesProps {
  // eslint-disable-next-line
  fields: GatsbyTypes.WpPage_Flexcontent_Flex_Stories
}

const AllStories: React.FC<AllStoriesProps> = ({ fields }) => (
  <section>
    <div className="container py-5">
      <div className="row justify-content-center">
        {fields.allstory?.map((story) => (
          <div className="col-lg-10 mb-lg-5 pb-5">
            <SecondaryPreview fields={story} />
          </div>
        ))}
      </div>
    </div>
  </section>
)

export default AllStories
