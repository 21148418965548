import React from 'react'
import { Link } from 'gatsby'

// Components
import Image from 'components/shared/Image'

// Third Party
import styled from 'styled-components'
import ParseContent from 'components/shared/ParseContent'

const StyledSecondaryPreview = styled.div``

const StyledImage = styled(Image)`
  position: absolute !important;
  width: 100%;
  height: 100%;
  z-index: -1;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.4);
`

const TriImage = styled(Image)`
  position: absolute !important;
  height: 350px;
  width: 300px;
  right: -30%;
  top: -50%;
  transition: all 0.3s ease;
  opacity: 0.8;

  & img {
    object-fit: contain !important;
  }

  @media screen and (max-width: 991px) {
    display: none;
  }
`

const Content = styled.div`
  color: ${(props) => props.theme.color.light};
  padding: 40px;

  & blockquote {
    margin-bottom: 0;
  }

  &:hover {
    ${TriImage} {
      transform: scale(1.05);
      opacity: 1;
    }
  }
`

interface SecondaryPreviewProps {
  // eslint-disable-next-line
  fields: GatsbyTypes.WpStory | undefined
}

const SecondaryPreview: React.FC<SecondaryPreviewProps> = ({ fields }) => (
  <StyledSecondaryPreview className="position-relative">
    <Link to={fields?.uri || '/'}>
      <StyledImage image={fields?.storyPreview?.backgroundImage} alt="" />
      <Content className="row">
        <div className="col-lg-8">
          <ParseContent content={fields?.storyPreview?.description} />
        </div>
        <div className="col-lg-4">
          <TriImage image={fields?.storyPreview?.image} alt="" />
        </div>
      </Content>
    </Link>
  </StyledSecondaryPreview>
)

export default SecondaryPreview
