import React from 'react'

// Components
import AllVacatures from 'components/flex/Vacature/AllVacatures'
import SelectionVacatures from 'components/flex/Vacature/SelectionVacatures'
import AllOfTypeVacatures from 'components/flex/Vacature/AllOfTypeVacatures'

interface VacatureShellProps {
  fields: any
  location?: any
}

interface VacaturesProps {
  [key: string]: any
}

const VacatureShell: React.FC<VacatureShellProps> = ({
  fields,
  location = {},
}) => {
  const banners: VacaturesProps = {
    all: AllVacatures,
    select: SelectionVacatures,
    all_of_type: AllOfTypeVacatures,
  }

  if (!fields.showType || !banners[fields.showType]) {
    return null
  }

  const Component = banners[fields.showType]

  return (
    <Component
      key={`${location ? location.pathname : 'flex'}`}
      fields={fields}
      location={location}
    />
  )
}

export default VacatureShell
