import React from 'react'
import styled from 'styled-components'

// Elements
import ButtonDefault from 'components/elements/Button/ButtonPrimary'
import Blogik, {
  BlogConsumer,
  BlogButton,
  BlogFilter,
} from 'components/shared/Blogik'

interface BlogProps {
  // eslint-disable-next-line
  fields: GatsbyTypes.WpPage_Flexcontent_Flex_Blog
  location: any
}

const Blog: React.FC<BlogProps> = ({ fields }) => (
  <section>
    <Blogik
      settings={{
        id: 'blog',
        limit: Number(fields.limit),
      }}
    >
      <div className="py-5">
        <BlogFilters />
        <BlogGrid fields={fields} />
      </div>
    </Blogik>
  </section>
)

const StyledBlogFilter = styled(BlogFilter)<{ selected: boolean }>`
  font-weight: ${(props) =>
    props.selected
      ? props.theme.font.weight.bold
      : props.theme.font.weight.light};
`

interface BlogFiltersProps {}

interface BlogFilterProps {
  node: {
    id: string
    name: string
  }
}

const BlogFilters: React.FC<BlogFiltersProps> = () => (
  <BlogConsumer>
    {({ categories, isSelected }: any) => (
      <div className="container d-flex">
        {categories.map((category: BlogFilterProps) => (
          <StyledBlogFilter
            key={category.node.id}
            id={category.node.id}
            selected={isSelected(category.node.id)}
            className="mr-3"
          >
            {category.node.name}
          </StyledBlogFilter>
        ))}
      </div>
    )}
  </BlogConsumer>
)

interface BlogGridProps {
  // eslint-disable-next-line
  fields: GatsbyTypes.WpPage_Flexcontent_Flex_Blog
}

const BlogGrid: React.FC<BlogGridProps> = ({ fields }) => (
  <BlogConsumer>
    {({ posts, showMoreButton }: any) => (
      <div className="container">
        <div className="row">
          {posts.map((post: BlogGridPostProps) => (
            <div key={post.node.id} className="col-4 mt-5">
              <BlogGridPost node={post.node} blogFields={fields} />
            </div>
          ))}
        </div>

        {showMoreButton && (
          <div className="mt-5 text-center">
            <ButtonDefault to="/" isCustom>
              <BlogButton>{fields.loadMoreText}</BlogButton>
            </ButtonDefault>
          </div>
        )}
      </div>
    )}
  </BlogConsumer>
)

interface BlogGridPostProps {
  // eslint-disable-next-line
  blogFields: GatsbyTypes.WpPage_Flexcontent_Flex_Blog
  node: {
    id: string
    title: string
    uri: string
  }
}

const BlogGridPost: React.FC<BlogGridPostProps> = ({ node, blogFields }) => (
  <div>
    <div>{node.title}</div>
    <div className="mt-2">
      <ButtonDefault to={node.uri}>{blogFields.readMoreText}</ButtonDefault>
    </div>
  </div>
)

export default Blog
