import React from 'react'
import styled from 'styled-components'
import ButtonShell from 'components/elements/Button/ButtonShell'

const StyledButton = styled.span`
  display: inline-block;

  & > a,
  & > button {
    background-color: ${(props) => props.theme.color.primary};
    color: ${(props) => props.theme.color.light};
    font-weight: ${(props) => props.theme.font.weight.bold};
    border: 5px solid transparent;
    font-size: 18px;
    padding: 20px 45px;
    width: 100%;
    height: 100%;
    display: block;
    border-radius: 8px;
    transition: all 0.3s ease;

    &:hover {
      background-color: ${(props) => props.theme.color.light};
      color: ${(props) => props.theme.color.primary};
      border: 5px solid ${(props) => props.theme.color.primary};
    }

    @media screen and (max-width: 576px) {
      font-size: 14px;
      padding: 10px 25px;
    }
  }
`

interface ButtonPrimaryProps {
  isAnchor?: boolean
  isCustom?: boolean
  to: string
  children: any
  className?: string
}

const ButtonPrimary: React.FC<ButtonPrimaryProps> = ({
  isAnchor = false,
  isCustom = false,
  to,
  children,
  className = '',
}) => (
  <StyledButton className={className}>
    {isCustom ? (
      children
    ) : (
      <ButtonShell to={to} isAnchor={isAnchor}>
        {children}
      </ButtonShell>
    )}
  </StyledButton>
)

export default ButtonPrimary
