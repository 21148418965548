import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'

// Components
import Preview from 'components/elements/Showcase/Preview'

interface AllShowcasesProps {
  // eslint-disable-next-line
  fields: GatsbyTypes.WpPage_Flexcontent_Flex_Showcases
}

const AllShowcases: React.FC<AllShowcasesProps> = () => {
  const query = useStaticQuery<GatsbyTypes.allShowcasesQuery>(graphql`
    query allShowcases {
      allWpShowcase {
        nodes {
          ...generalShowcaseFragment
        }
      }
    }
  `)

  return (
    <section>
      <div className="container py-5">
        <div className="row">
          {query.allWpShowcase.nodes.map((showcase) => (
            <div className="col-lg-6 pb-lg-5 pb-4">
              {/* @ts-ignore */}
              <Preview fields={showcase} />
            </div>
          ))}
        </div>
      </div>
    </section>
  )
}

export default AllShowcases
