import React from 'react'

// Images
import Settings from 'img/settings.inline.svg'
import Pin from 'img/pin.inline.svg'
import Clock from 'img/clock.inline.svg'

// Components
import CustomLink from 'components/shared/CustomLink'
import ParseContent from 'components/shared/ParseContent'

// Third Party
import styled from 'styled-components'

const StyledVacatureGridPost = styled.div`
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.4);

  & svg {
    width: 20px;
    height: 20px;
    fill: #d2d2d2;
  }

  & p {
    font-size: 14px;
    line-height: 30px;
  }
`

const Button = styled(CustomLink)`
  color: ${(props) => props.theme.color.primary};
  padding-right: 20px;

  &:hover {
    color: ${(props) => props.theme.color.primary};
    text-decoration: underline;
  }

  &:before {
    content: '';
    position: absolute;
    border-left: 20px solid ${(props) => props.theme.color.primary};
    border-top: 12px solid transparent;
    border-right: 20px solid transparent;
    border-bottom: 12px solid transparent;
    right: 30px;
  }
`

export interface VacatureGridPostProps {
  // eslint-disable-next-line
  blogFields: GatsbyTypes.WpPage_Flexcontent_Flex_Vacatures
  // eslint-disable-next-line
  node: GatsbyTypes.WpVacature
}

const VacatureGridPost: React.FC<VacatureGridPostProps> = ({
  node,
  blogFields,
}) => (
  <StyledVacatureGridPost className="bg-light px-md-5 px-3 py-md-4 pt-4 pb-2">
    <div className="d-flex flex-wrap-reverse pb-3 justify-content-between align-items-center">
      <div className="font-size-24 font-weight-bold pr-5">{node.title}</div>
      <Button
        className="font-size-16 pb-md-0 pb-2 font-weight-bold"
        to={node.uri}
      >
        {blogFields.readMoreText}
      </Button>
    </div>
    <ParseContent content={node.vacaturePreview?.description} />
    {node.flexContent?.flex?.find(
      (f) => f?.fieldGroupName === 'vacature_Flexcontent_Flex_Banner'
    ) && (
      <div className="d-flex flex-wrap pt-4">
        <div className="d-flex align-items-center pb-md-0 pb-2 mr-4">
          <Settings />
          <div className="ml-2 font-size-16">
            {
              node.flexContent?.flex.filter(
                // eslint-disable-next-line
                (f) => f?.fieldGroupName === 'vacature_Flexcontent_Flex_Banner'
                // @ts-ignore
              )[0]?.info?.vacatureType
            }
          </div>
        </div>
        <div className="d-flex align-items-center pb-md-0 pb-2 mr-4">
          <Pin />
          <div className="ml-2 font-size-16">
            {
              node.flexContent?.flex.filter(
                // eslint-disable-next-line
                (f) => f?.fieldGroupName === 'vacature_Flexcontent_Flex_Banner'
                // @ts-ignore
              )[0]?.info?.place
            }
          </div>
        </div>
        <div className="d-flex align-items-center pb-md-0 pb-2">
          <Clock />
          <div className="ml-2 font-size-16">
            {
              node.flexContent?.flex.filter(
                // eslint-disable-next-line
                (f) => f?.fieldGroupName === 'vacature_Flexcontent_Flex_Banner'
                // @ts-ignore
              )[0]?.info?.time
            }
          </div>
        </div>
      </div>
    )}
  </StyledVacatureGridPost>
)

export default VacatureGridPost
