import React from 'react'
import { Link } from 'gatsby'

// Components
import Image from 'components/shared/Image'

// Third Party
import styled from 'styled-components'

const StyledImage = styled(Image)``

const Content = styled.div`
  position: relative;
  font-family: ${(props) => props.theme.font.family.secondary};
  text-transform: uppercase;
  font-weight: ${(props) => props.theme.font.weight.bold};
  font-size: 24px;
  transition: all 0.3s ease;

  &:before {
    content: '';
    position: absolute;
    right: 15px;
    top: 16px;
    border-left: 20px solid ${(props) => props.theme.color.primary};
    border-top: 13px solid transparent;
    border-right: 20px solid transparent;
    border-bottom: 13px solid transparent;
  }
`

const StyledPreview = styled.div`
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  transition: all 0.3s ease;

  &:hover {
    background-color: ${(props) => props.theme.color.dark};

    ${Content} {
      color: ${(props) => props.theme.color.light};
    }
  }
`

interface PreviewProps {
  // eslint-disable-next-line
  fields: GatsbyTypes.WpShowcase
}

const Preview: React.FC<PreviewProps> = ({ fields }) => (
  <Link to={fields.uri}>
    <StyledPreview>
      <StyledImage alt="" image={fields.showcasePreview?.image} />
      <Content className="px-4 py-3">{fields.title}</Content>
    </StyledPreview>
  </Link>
)
export default Preview
