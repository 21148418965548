import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'

// Components
import Blogik from 'components/shared/Blogik'
import VacatureGrid from 'components/elements/Vacature/VacatureGrid'
import Title from 'components/elements/TitlePrimary'
import ButtonPrimary from 'components/elements/Button/ButtonPrimary'

interface SelectionVacaturesProps {
  // eslint-disable-next-line
  fields: GatsbyTypes.WpPage_Flexcontent_Flex_Vacatures
}

const SelectionVacatures: React.FC<SelectionVacaturesProps> = ({ fields }) => {
  // eslint-disable-next-line
  const query = useStaticQuery<GatsbyTypes.SelectionVacaturesQuery>(graphql`
    query SelectionVacatures {
      allWpVacature {
        totalCount
      }
    }
  `)

  return (
    <section>
      <div className="container py-5">
        <Title content="VACATURES" />
        <div className="row justify-content-center">
          <div className="col-lg-10">
            <Blogik
              settings={{
                id: 'select-vacature',
                type: 'vacature',
                postIds: fields.vacancies?.map((v: any) => v.id),
                limit: Infinity,
              }}
            >
              <div className="py-4">
                <VacatureGrid fields={fields} />
              </div>
            </Blogik>
          </div>
        </div>
        <div className="d-flex justify-content-center pt-5 pb-4">
          <ButtonPrimary to="/vacatures">{`Bekijk alle vacatures (${query.allWpVacature.totalCount})`}</ButtonPrimary>
        </div>
      </div>
    </section>
  )
}

export default SelectionVacatures
