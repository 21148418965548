import React from 'react'

// Components
import { BlogConsumer, BlogButton } from 'components/shared/Blogik'
import ButtonDefault from 'components/elements/Button/ButtonPrimary'
import VacatureGridPost, {
  VacatureGridPostProps,
} from 'components/elements/Vacature/VacatureGridPost'

interface BlogGridProps {
  // eslint-disable-next-line
  fields: GatsbyTypes.WpPost_Flexcontent_Flex_Vacatures
}

const VacatureGrid: React.FC<BlogGridProps> = ({ fields }) => (
  <BlogConsumer>
    {({ posts, showMoreButton }: any) => (
      <div>
        <div className="row">
          {posts.map((post: VacatureGridPostProps) => (
            <div key={post.node.id} className="col-12 mt-lg-5 mt-4">
              <VacatureGridPost node={post.node} blogFields={fields} />
            </div>
          ))}
        </div>

        {showMoreButton && (
          <div className="mt-5 text-center">
            <ButtonDefault to="/" isCustom>
              <BlogButton>{fields.loadMoreText}</BlogButton>
            </ButtonDefault>
          </div>
        )}
      </div>
    )}
  </BlogConsumer>
)

export default VacatureGrid
