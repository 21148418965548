import React from 'react'

// Components
import SelectionStories from 'components/flex/Story/SelectionStories'
import AllStories from 'components/flex/Story/AllStories'

interface StoryShellProps {
  fields: any
  location?: any
}

interface StoriesProps {
  [key: string]: any
}

const StoryShell: React.FC<StoryShellProps> = ({ fields, location = {} }) => {
  const stories: StoriesProps = {
    select: SelectionStories,
    all: AllStories,
  }

  if (!fields.styleType || !stories[fields.styleType]) {
    return null
  }

  const Component = stories[fields.styleType]

  return (
    <Component
      key={`${location ? location.pathname : 'flex'}`}
      fields={fields}
      location={location}
    />
  )
}

export default StoryShell
