import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'

// Components
import Image from 'components/shared/Image'
import ParseContent from 'components/shared/ParseContent'

// Third Party
import styled from 'styled-components'

interface BannerVacatureProps {
  // eslint-disable-next-line
  fields: GatsbyTypes.WpPost_Flexcontent_Flex_Banner
  location?: any
}

const StyledBannerVacature = styled.section`
  position: relative;
  z-index: -1;
`

const BannerWrapper = styled.div`
  position: relative;
  max-width: 2000px;

  @media screen and (min-width: 2000px) {
    margin-left: auto;
    margin-right: auto;
  }
`

const Lasers = styled(Image)`
  position: absolute !important;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: -1;

  @media screen and (max-width: 991px) {
    opacity: 0.6;

    & img {
      object-fit: fill !important;
    }
  }
`

const CutOut = styled.div`
  clip-path: polygon(0 0, 100% 0, 100% 63%, 0% 100%);
  height: 780px;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;

  @media screen and (max-width: 1600px) {
    clip-path: polygon(0 0, 100% 0, 100% 70%, 0% 100%);
  }

  @media screen and (max-width: 1400px) {
    height: 650px;
    clip-path: polygon(0 0, 100% 0, 100% 70%, 0% 100%);
  }

  @media screen and (max-width: 991px) {
    width: 100vw;
    padding-top: 10px;
    height: 580px;
    clip-path: polygon(0 0, 100% 0, 100% 90%, 0% 100%);
    background-color: ${(props) => props.theme.color.dark};
  }
`

const ImageWrapper = styled.div`
  position: absolute;
  height: 80%;
  width: 850px;
  right: 0;
  top: 0;

  @media screen and (max-width: 1500px) {
    width: 700px;
    height: 80%;
  }

  @media screen and (max-width: 1300px) {
    width: 600px;
    height: 80%;
  }

  @media screen and (max-width: 991px) {
    display: none;
  }
`

const Shadow = styled.div`
  width: 500px;
  height: 200%;
  position: absolute;
  left: -250px;
  z-index: 2;

  background: rgb(0, 0, 0);
  background: -moz-linear-gradient(
    90deg,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 1) 50%,
    rgba(0, 0, 0, 0) 100%
  );
  background: -webkit-linear-gradient(
    90deg,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 1) 50%,
    rgba(0, 0, 0, 0) 100%
  );
  background: linear-gradient(
    90deg,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 1) 50%,
    rgba(0, 0, 0, 0) 100%
  );
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#000000",endColorstr="#000000",GradientType=1);
`

const StyledImage = styled(Image)`
  position: relative !important;
  width: 100%;
  height: 100%;
  z-index: 1;
`

const Content = styled(ParseContent)`
  color: ${(props) => props.theme.color.light};
  position: relative;
  z-index: 3;

  & h1 {
    margin-bottom: 100px;
    position: relative;

    &:before {
      content: '';
      position: absolute;
      height: 5px;
      width: 2530px;
      background-color: ${(props) => props.theme.color.primary};
      bottom: -50px;
      left: -2000px;
    }
  }

  & p {
    padding-right: 250px;
    line-height: 30px;
  }

  @media screen and (max-width: 991px) {
    & h1 {
      margin-bottom: 50px;

      &:before {
        bottom: -25px;
      }
    }

    & p {
      padding-right: 0;
    }
  }
`

const BannerVacature: React.FC<BannerVacatureProps> = ({ fields }) => {
  const {
    laser,
    // eslint-disable-next-line
  } = useStaticQuery<GatsbyTypes.BannerStoryQuery>(graphql`
    query BannerStory {
      laser: file(name: { eq: "other-lasers" }) {
        childImageSharp {
          gatsbyImageData(quality: 100, width: 2000)
        }
      }
    }
  `)

  return (
    <StyledBannerVacature className="position-relative">
      <BannerWrapper>
        <CutOut>
          <Lasers alt="banner" image={{ localFile: laser }} />
          <ImageWrapper>
            <Shadow />
            <StyledImage alt="" image={fields.image} />
          </ImageWrapper>
        </CutOut>

        <div className="h-100 container d-flex py-lg-5 py-3">
          <div className="row justify-content-start">
            <div className="col-lg-8 pt-4 pb-5">
              <Content content={fields.description} />
            </div>
          </div>
        </div>
      </BannerWrapper>
    </StyledBannerVacature>
  )
}
export default BannerVacature
