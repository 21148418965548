import React from 'react'

// Components
import BannerHome from 'components/flex/Banner/BannerHome'
import BannerDetail from 'components/flex/Banner/BannerDetail'
import BannerVacature from 'components/flex/Banner/BannerVacature'
import BannerStory from 'components/flex/Banner/BannerStory'
import BannerNoBackground from 'components/flex/Banner/BannerNoBackground'

interface BannerShellProps {
  fields: any
  location?: any
}

interface BannersProps {
  [key: string]: any
}

const BannerShell: React.FC<BannerShellProps> = ({ fields, location = {} }) => {
  const banners: BannersProps = {
    home: BannerHome,
    detail: BannerDetail,
    vacature: BannerVacature,
    story: BannerStory,
    'no-background': BannerNoBackground,
  }

  if (!fields.styleType || !banners[fields.styleType]) {
    return null
  }

  const Component = banners[fields.styleType]

  return (
    <Component
      key={`${location ? location.pathname : 'flex'}`}
      fields={fields}
      location={location}
    />
  )
}

export default BannerShell
