import React from 'react'

// Components
import Image from 'components/shared/Image'

// Third Party
import styled from 'styled-components'
import ParseContent from 'components/shared/ParseContent'

const StyledUSP = styled.div`
  position: relative;
`

const ImageWrapper = styled.div`
  position: absolute;
  height: 100%;
  width: 100%;
  max-width: 2000px;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  background-color: ${(props) => props.theme.color.dark};
`

const StyledImage = styled(Image)`
  position: absolute !important;
  height: 100%;
  width: 100%;
`

const Shadow = styled.div`
  position: absolute;
  height: 100%;
  width: 100%;
  z-index: 2;
  background: rgb(16, 16, 16);
  background: -moz-linear-gradient(
    90deg,
    rgba(16, 16, 16, 1) 0%,
    rgba(16, 16, 16, 0) 25%,
    rgba(16, 16, 16, 0) 75%,
    rgba(16, 16, 16, 1) 100%
  );
  background: -webkit-linear-gradient(
    90deg,
    rgba(16, 16, 16, 1) 0%,
    rgba(16, 16, 16, 0) 25%,
    rgba(16, 16, 16, 0) 75%,
    rgba(16, 16, 16, 1) 100%
  );
  background: linear-gradient(
    90deg,
    rgba(16, 16, 16, 1) 0%,
    rgba(16, 16, 16, 0) 25%,
    rgba(16, 16, 16, 0) 75%,
    rgba(16, 16, 16, 1) 100%
  );
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#101010",endColorstr="#101010",GradientType=1);
`

const ShadowWrapper = styled.div`
  position: absolute;
  height: 100%;
  width: 1000px;
`

const Content = styled.div`
  position: relative;
  z-index: 10;
  color: ${(props) => props.theme.color.light};

  & p {
    font-size: 14px;
    line-height: 18px;
    font-weight: ${(props) => props.theme.font.weight.light};

    @media screen and (max-width: 991px) {
      line-height: 22px;
    }
  }

  & h2 {
    @media (min-width: 992px) {
      font-size: 40px;
      line-height: 40px;
    }

    @media (max-width: 991px) {
      font-size: 20px;
      line-height: 20px;
    }

    &:nth-of-type(1) {
      padding-left: 0px;
      position: relative;

      & + p {
        padding-left: 0px;
        padding-bottom: 20px;
      }

      &:before {
        content: '';
        position: absolute;
        border-left: 18px solid ${(props) => props.theme.color.primary};
        border-top: 13px solid transparent;
        border-right: 18px solid transparent;
        border-bottom: 13px solid transparent;
        left: -35px;
        top: 6px;
      }

      @media screen and (max-width: 991px) {
        padding-left: 30px;

        & + p {
          padding-left: 30px;
        }

        &:before {
          left: 0px;
          top: -4px;
        }
      }
    }

    &:nth-of-type(2) {
      padding-left: 40px;
      position: relative;

      & + p {
        padding-left: 40px;
        padding-bottom: 20px;
      }

      &:before {
        content: '';
        position: absolute;
        border-left: 18px solid ${(props) => props.theme.color.primary};
        border-top: 13px solid transparent;
        border-right: 18px solid transparent;
        border-bottom: 13px solid transparent;
        left: 5px;
        top: 6px;
      }

      @media screen and (max-width: 991px) {
        padding-left: 70px;

        & + p {
          padding-left: 70px;
        }

        &:before {
          left: 40px;
          top: -4px;
        }
      }
    }

    &:nth-of-type(3) {
      padding-left: 80px;
      position: relative;

      & + p {
        padding-left: 80px;
        padding-bottom: 20px;
      }

      &:before {
        content: '';
        position: absolute;
        border-left: 18px solid ${(props) => props.theme.color.primary};
        border-top: 13px solid transparent;
        border-right: 18px solid transparent;
        border-bottom: 13px solid transparent;
        left: 45px;
        top: 6px;
      }

      @media screen and (max-width: 991px) {
        padding-left: 110px;

        & + p {
          padding-left: 110px;
        }

        &:before {
          left: 80px;
          top: -4px;
        }
      }
    }
  }
`

const Container = styled.div`
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;

  @media screen and (min-width: 1200px) {
    max-width: 1440px;
  }
`

interface USPProps {
  // eslint-disable-next-line
  fields: GatsbyTypes.WpPage_Flexcontent_Flex_Text
}

const USP: React.FC<USPProps> = ({ fields }) => (
  <section>
    <StyledUSP className="my-5">
      <ImageWrapper>
        <ShadowWrapper>
          <Shadow />
          <StyledImage image={fields.backgroundImage} alt="" />
        </ShadowWrapper>
      </ImageWrapper>
      <Container className="py-lg-5 py-3">
        <div className="row py-5 justify-content-end">
          <div className="col-lg-6">
            <Content>
              <ParseContent content={fields.description} />
            </Content>
          </div>
        </div>
      </Container>
    </StyledUSP>
  </section>
)

export default USP
