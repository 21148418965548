import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'

// Components
import Blogik from 'components/shared/Blogik'
import VacatureFilters from 'components/elements/Vacature/VacatureFilters'
import VacatureGrid from 'components/elements/Vacature/VacatureGrid'

interface AllOfTypeVacaturesProps {
  // eslint-disable-next-line
  fields: GatsbyTypes.WpPost_Flexcontent_Flex_Vacatures
  location: any
}

const AllOfTypeVacatures: React.FC<AllOfTypeVacaturesProps> = ({
  fields,
  location,
}) => {
  // eslint-disable-next-line
  const query = useStaticQuery<GatsbyTypes.AllOfTypeQuery>(graphql`
    query AllOfType {
      allWpVacatureType {
        nodes {
          name
          id
          slug
        }
      }
    }
  `)

  const typeId = query.allWpVacatureType.nodes.filter(
    (vt) => vt.slug === location.pathname.split('/')[2]
  )[0]?.id

  return (
    <section>
      <div className="container py-3">
        <div className="row justify-content-center">
          <div className="col-lg-10">
            <Blogik
              settings={{
                id: 'all-of-type-vacature',
                type: 'vacature',
                limit: Infinity,
                presetFilters: [typeId],
              }}
            >
              <div className="py-5">
                <VacatureFilters />
                <div className="pt-lg-5">
                  <VacatureGrid fields={fields} />
                </div>
              </div>
            </Blogik>
          </div>
        </div>
      </div>
    </section>
  )
}

export default AllOfTypeVacatures
