import React from 'react'

// Components
import Form from 'components/flex/Form/Form'
import Apply from 'components/flex/Form/Apply'

interface FormShellProps {
  fields: any
  location?: any
  title?: string
}

interface FormsProps {
  [key: string]: any
}

const FormShell: React.FC<FormShellProps> = ({
  fields,
  location = {},
  title,
}) => {
  const forms: FormsProps = {
    default: Form,
    apply: Apply,
  }

  if (!fields.styleType || !forms[fields.styleType]) {
    return null
  }

  const Component = forms[fields.styleType]

  return (
    <Component
      key={`${location ? location.pathname : 'flex'}`}
      fields={fields}
      location={location}
      title={title}
    />
  )
}

export default FormShell
