import React from 'react'

// Images
import Mail from 'img/email.inline.svg'
import Facebook from 'img/facebook.inline.svg'
import Whatsapp from 'img/whatsapp.inline.svg'
import Linkedin from 'img/other-linkedin.inline.svg'
import Twitter from 'img/twitter.inline.svg'

// Third Party
import {
  EmailShareButton,
  LinkedinShareButton,
  FacebookShareButton,
  WhatsappShareButton,
  TwitterShareButton,
} from 'react-share'
import styled from 'styled-components'

const Icon = styled.div`
  height: 38px;
  width: 38px;
  border-radius: 45px;
  background-color: ${(props) => props.theme.color.dark};
  transition: all 0.3s ease;

  & svg {
    height: 20px;
    width: 20px;
    fill: #ffffff;
  }

  &:hover {
    background-color: ${(props) => props.theme.color.primary};
  }
`

interface ShareProps {
  location: any
  subject?: string
}

const Share: React.FC<ShareProps> = ({ location, subject = '' }) => {
  const shareUrl = location ? location.href : ''

  const removeHTML = (content: any) => {
    let newContent = content

    if (typeof document !== 'undefined') {
      const titleHtml = newContent
      const div = document.createElement('div')
      div.innerHTML = titleHtml
      newContent = div.innerText
    }

    return newContent
  }

  return (
    <div className="d-flex align-items-center">
      <EmailShareButton
        className="mr-2"
        url={shareUrl}
        subject={removeHTML(subject)}
        body={removeHTML(shareUrl)}
      >
        <Icon className="d-flex justify-content-center align-items-center">
          <Mail />
        </Icon>
      </EmailShareButton>
      <FacebookShareButton url={shareUrl} className="mr-2">
        <Icon className="d-flex justify-content-center align-items-center">
          <Facebook />
        </Icon>
      </FacebookShareButton>
      <WhatsappShareButton url={shareUrl} className="mr-2">
        <Icon className="d-flex justify-content-center align-items-center">
          <Whatsapp />
        </Icon>
      </WhatsappShareButton>
      <LinkedinShareButton url={shareUrl} className="mr-2">
        <Icon className="d-flex justify-content-center align-items-center">
          <Linkedin />
        </Icon>
      </LinkedinShareButton>
      <TwitterShareButton url={shareUrl}>
        <Icon className="d-flex justify-content-center align-items-center">
          <Twitter />
        </Icon>
      </TwitterShareButton>
    </div>
  )
}

export default Share
