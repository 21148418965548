import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'

// Components
import Image from 'components/shared/Image'
import ParseContent from 'components/shared/ParseContent'

// Third Party
import styled from 'styled-components'

interface BannerHomeProps {
  // eslint-disable-next-line
  fields: GatsbyTypes.WpPost_Flexcontent_Flex_Banner
  location?: any
}

const StyledBannerHome = styled.section``

const BannerWrapper = styled.div`
  position: relative;
  height: 825px;
  max-width: 2000px;
  clip-path: polygon(0 0, 100% 0, 100% 63%, 0% 100%);

  @media screen and (min-width: 2000px) {
    margin-left: auto;
    margin-right: auto;
  }

  @media screen and (max-width: 1600px) {
    max-width: 1600px;
    clip-path: polygon(0 0, 100% 0, 100% 70%, 0% 100%);
  }

  @media screen and (max-width: 1400px) {
    height: 700px;
    max-width: 1400px;
    clip-path: polygon(0 0, 100% 0, 100% 70%, 0% 100%);
  }

  @media screen and (max-width: 991px) {
    padding-top: 10px;
    height: fit-content;
    padding: 40px 0 40px 0;
    clip-path: polygon(0 0, 100% 0, 100% 90%, 0% 100%);
    background-color: ${(props) => props.theme.color.dark};
  }
`

const Lasers = styled(Image)`
  position: absolute !important;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: -1;

  @media screen and (max-width: 991px) {
    opacity: 0.6;

    & img {
      object-fit: fill !important;
    }
  }
`

const Dorito = styled(Image)`
  position: absolute !important;
  right: 90px;
  top: 50px;
  width: 798px;
  height: 692px;
  z-index: 2;
  opacity: 0.69;
  pointer-events: none;

  @media screen and (min-width: 2000px) {
    right: 20%;
  }

  @media screen and (max-width: 1500px) {
    opacity: 0.3;
    right: -50px;
    width: 500px;
    height: 560px;
  }

  @media screen and (max-width: 991px) {
    display: none;
  }
`

const ImageWrapper = styled.div`
  position: absolute;
  width: 1030px;
  right: 0;
  top: 0;

  @media screen and (max-width: 1500px) {
    width: 800px;
    height: 80%;
  }

  @media screen and (max-width: 1300px) {
    width: 600px;
    height: 80%;
  }

  @media screen and (max-width: 991px) {
    display: none;
  }
`

const Shadow = styled.div`
  width: 500px;
  height: 200%;
  position: absolute;
  left: -250px;
  z-index: 2;

  background: rgb(0, 0, 0);
  background: -moz-linear-gradient(
    90deg,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 1) 50%,
    rgba(0, 0, 0, 0) 100%
  );
  background: -webkit-linear-gradient(
    90deg,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 1) 50%,
    rgba(0, 0, 0, 0) 100%
  );
  background: linear-gradient(
    90deg,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 1) 50%,
    rgba(0, 0, 0, 0) 100%
  );
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#000000",endColorstr="#000000",GradientType=1);
`

const StyledImage = styled(Image)`
  position: relative;
  width: 100%;
  height: 100%;
  z-index: 1;
`

const Content = styled(ParseContent)`
  color: ${(props) => props.theme.color.light};
  position: relative;
  z-index: 3;

  & h1 {
    margin-bottom: 100px;
    position: relative;

    &:before {
      content: '';
      position: absolute;
      height: 5px;
      width: 2530px;
      background-color: ${(props) => props.theme.color.primary};
      bottom: -50px;
      left: -2000px;
    }
  }

  & p {
    padding-right: 31%;
  }

  @media screen and (max-width: 991px) {
    & h1 {
      margin-bottom: 50px;

      &:before {
        bottom: -25px;
      }
    }

    & p {
      padding-right: 0;
    }
  }
`

const BannerHome: React.FC<BannerHomeProps> = ({ fields }) => {
  const {
    laser,
    triangle,
    allWpVacature: { totalCount },
    // eslint-disable-next-line
  } = useStaticQuery<GatsbyTypes.BannerHomeQueryQuery>(graphql`
    query BannerHomeQuery {
      laser: file(name: { eq: "other-lasers" }) {
        childImageSharp {
          gatsbyImageData(quality: 100, width: 2000)
        }
      }

      triangle: file(name: { eq: "triangle" }) {
        childImageSharp {
          gatsbyImageData(quality: 100, width: 1000, placeholder: NONE)
        }
      }

      allWpVacature {
        totalCount
      }
    }
  `)

  return (
    <StyledBannerHome className="position-relative">
      <Dorito alt="triangle" image={{ localFile: triangle }} />
      <BannerWrapper>
        <Lasers alt="banner" image={{ localFile: laser }} />
        <div className="h-100 container d-flex align-items-center">
          <div className="row justify-content-start">
            <ImageWrapper>
              <Shadow />
              <StyledImage alt="" image={fields.image} />
            </ImageWrapper>
            <div className="col-lg-8">
              <Content
                content={fields.description?.replace(
                  `{vacature-counter}`,
                  `(${totalCount})`
                )}
              />
            </div>
          </div>
        </div>
      </BannerWrapper>
    </StyledBannerHome>
  )
}
export default BannerHome
