import React from 'react'

// Components
import Text from 'components/flex/Text/Text'
import TextWithFragment from 'components/flex/Text/TextWithFragment'
import VacancyTextWithFragment from 'components/flex/Text/VacancyTextWithFragment'
import TextWithVideo from 'components/flex/Text/TextWithVideo'
import TextWithImage from 'components/flex/Text/TextWithImage'
import Header from 'components/flex/Text/Header'
import Video from 'components/flex/Text/Video'
import USP from 'components/flex/Text/USP'

interface TextShellProps {
  fields: any
  location?: any
  title?: string
}

interface TextsProps {
  [key: string]: any
}

const TextShell: React.FC<TextShellProps> = ({
  fields,
  location = {},
  title,
}) => {
  const texts: TextsProps = {
    text: Text,
    header: Header,
    text_with_fragment: TextWithFragment,
    vacancy_text_with_fragment: VacancyTextWithFragment,
    text_with_image: TextWithImage,
    text_with_video: TextWithVideo,
    video: Video,
    usp: USP,
  }

  if (!fields.styleType || !texts[fields.styleType]) {
    return null
  }

  const Component = texts[fields.styleType]

  return (
    <Component
      key={`${location ? location.pathname : 'flex'}`}
      fields={fields}
      location={location}
      title={title}
    />
  )
}

export default TextShell
