import React from 'react'

// Components
import Blogik from 'components/shared/Blogik'
import VacatureFilters from 'components/elements/Vacature/VacatureFilters'
import VacatureGrid from 'components/elements/Vacature/VacatureGrid'

interface AllVacaturesProps {
  // eslint-disable-next-line
  fields: GatsbyTypes.WpPost_Flexcontent_Flex_Vacatures
}

const AllVacatures: React.FC<AllVacaturesProps> = ({ fields }) => (
  <section>
    <div className="container py-3">
      <div className="row justify-content-center">
        <div className="col-lg-10">
          <Blogik
            settings={{
              id: 'all-vacature',
              type: 'vacature',
              limit: Infinity,
            }}
          >
            <div className="py-lg-5 py-3">
              <VacatureFilters />
              <div className="pt-lg-5 pt-3 mt-lg-5">
                <VacatureGrid fields={fields} />
              </div>
            </div>
          </Blogik>
        </div>
      </div>
    </div>
  </section>
)

export default AllVacatures
