import React, { useState } from 'react'

// Images
import PlayButtonIcon from 'img/play-button.inline.svg'

// Components
import Image from 'components/shared/Image'

// Third Party
import styled from 'styled-components'
import { motion, AnimatePresence } from 'framer-motion'

const Thumbnail = styled(Image)``

const PlayButton = styled.div`
  background-color: ${(props) => props.theme.color.primary};
  width: 45px;
  height: 45px;
  border-radius: 23px;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  margin: auto;
  z-index: 2;
  transition: all 0.3s ease;

  & svg {
    padding-left: 4px;
    width: 22px;
    height: 22px;
    fill: #ffffff;
  }
`

const ThumbnailWrapper = styled.div`
  cursor: pointer;

  &:hover {
    ${PlayButton} {
      background-color: ${(props) => props.theme.color.secondary};

      & svg {
        fill: #d70926;
      }
    }
  }
`

const Modal = styled(motion.div)`
  background-color: rgba(0, 0, 0, 0.4);
  backdrop-filter: blur(10px);

  @media screen and (max-width: 768px) {
    position: relative;
    width: 90%;
    background-color: transparent;
    backdrop-filter: none;

    & iframe {
      width: 100%;
    }
  }
`

const ModalContainer = styled(motion.div)`
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  z-index: 100;
  cursor: pointer;
`

interface VideoProps {
  // eslint-disable-next-line
  fields: GatsbyTypes.WpPage_Flexcontent_Flex_Text
}

const Video: React.FC<VideoProps> = ({ fields }) => {
  const [open, setOpen] = useState(false)

  return (
    <div>
      <ThumbnailWrapper
        onClick={() => setOpen(true)}
        className="position-relative"
      >
        <PlayButton className="d-flex justify-content-center align-items-center">
          <PlayButtonIcon />
        </PlayButton>
        <Thumbnail image={fields.thumbnail} alt="" />
      </ThumbnailWrapper>
      <AnimatePresence>
        {open && (
          <ModalContainer
            initial={{
              backgroundColor: 'rgba(0,0,0,0)',
              backdropFilter: 'blur(0px)',
            }}
            animate={{
              backgroundColor: 'rgba(0,0,0,0.6)',
              backdropFilter: 'blur(8px)',
            }}
            exit={{
              backgroundColor: 'rgba(0,0,0,0)',
              backdropFilter: 'blur(0px)',
            }}
            className="d-flex justify-content-center align-items-center"
            onClick={() => setOpen(false)}
          >
            <Modal
              initial={{ y: 500, opacity: 0 }}
              animate={{ y: 0, opacity: 1 }}
              exit={{ y: 100, opacity: 0 }}
            >
              <iframe
                title="video"
                src={fields.video}
                width="640"
                height="360"
                frameBorder="0"
                allow="autoplay; fullscreen; picture-in-picture"
                allowFullScreen
              />
            </Modal>
          </ModalContainer>
        )}
      </AnimatePresence>
    </div>
  )
}

export default Video
