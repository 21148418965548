import React, { useState, useEffect } from 'react'
import { navigate } from 'gatsby'

// Components
import ParseContent from 'components/shared/ParseContent'
import GravityForm from 'components/shared/GravityForm'

// Third Party
import styled from 'styled-components'
import { AnimatePresence, motion } from 'framer-motion'

const FormBlock = styled.div`
  border: 3px solid ${(props) => props.theme.color.primary};
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  background-color: ${(props) => props.theme.color.light};
  position: relative;
  z-index: 110;
`

const Anchor = styled.div`
  position: absolute;
  top: -100px;
`

const Focus = styled(motion.div)`
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  cursor: pointer;
`

interface ApplyProps {
  // eslint-disable-next-line
  fields: GatsbyTypes.WpPage_Flexcontent_Flex_Form
  location: any
  title?: string
}

const Apply: React.FC<ApplyProps> = ({ fields, location, title }) => {
  const [focus, setFocus] = useState(false)

  useEffect(() => {
    if (location.hash === '#solliciteer-direct') {
      setFocus(true)
    } else {
      setFocus(false)
    }
  }, [location.hash])

  return (
    <section className="position-relative">
      <Anchor id="solliciteer-direct" />
      <Anchor id="solliciteer" />
      <AnimatePresence>
        {focus && (
          <Focus
            onClick={() => navigate('#solliciteer')}
            initial={{
              backgroundColor: 'rgba(0,0,0,0)',
              backdropFilter: 'blur(0px)',
            }}
            animate={{
              backgroundColor: 'rgba(0,0,0,0.5)',
              backdropFilter: 'blur(4px)',
            }}
            exit={{
              backgroundColor: 'rgba(0,0,0,0)',
              backdropFilter: 'blur(0px)',
            }}
          />
        )}
      </AnimatePresence>
      <FormBlock className="p-3">
        <ParseContent content={fields.description} />
        <div className="py-3" />
        <GravityForm title={title} id={fields.formId || 1} />
      </FormBlock>
    </section>
  )
}

export default Apply
