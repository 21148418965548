import React, { DOMElement } from 'react'
import { useStaticQuery, graphql } from 'gatsby'

// Components
import ButtonPrimary from 'components/elements/Button/ButtonPrimary'
import CustomLink from 'components/shared/CustomLink'
import ButtonSecondary from 'components/elements/Button/ButtonSecondary'

// Third Party
import styled from 'styled-components'
import { renderToString } from 'react-dom/server'
import parse, { domToReact } from 'html-react-parser'

const Content = styled.div<{ isLightHs: boolean }>`
  font-weight: ${(props) => props.theme.font.weight.light};
  font-size: ${(props) => props.theme.font.size[16]};

  & strong,
  & b {
    font-weight: ${(props) => props.theme.font.weight.bold};
  }

  & h1,
  & h2,
  & h3,
  & h4,
  & h5 {
    text-align: initial;

    &:last-child {
      margin-bottom: 0;
    }
  }

  & h1 {
    font-weight: ${(props) => props.theme.font.weight.regular};
    font-family: ${(props) => props.theme.font.family.secondary};

    & strong,
    & b {
      font-weight: ${(props) => props.theme.font.weight.bold};
    }

    @media (min-width: 992px) {
      font-size: 65px;
      line-height: 65px;
    }

    @media (max-width: 991px) {
      font-size: 40px;
      line-height: 40px;
    }
  }

  & h2 {
    font-weight: ${(props) => props.theme.font.weight.regular};
    font-family: ${(props) => props.theme.font.family.secondary};

    & strong,
    & b {
      font-weight: ${(props) => props.theme.font.weight.bold};
    }

    @media (min-width: 992px) {
      font-size: 65px;
      line-height: 65px;
    }

    @media (max-width: 991px) {
      font-size: 40px;
      line-height: 40px;
    }
  }

  & h3 {
    font-weight: ${(props) => props.theme.font.weight.regular};
    font-family: ${(props) => props.theme.font.family.secondary};

    & strong,
    & b {
      font-weight: ${(props) => props.theme.font.weight.bold};
    }

    @media (min-width: 992px) {
      font-size: 36px;
      line-height: 36px;
    }

    @media (max-width: 991px) {
      font-size: 20px;
      line-height: 20px;
    }
  }

  & h4 {
    font-weight: ${(props) => props.theme.font.weight.bold};

    & strong,
    & b {
      font-weight: ${(props) => props.theme.font.weight.bold};
    }

    @media (min-width: 992px) {
      font-size: 20px;
      line-height: 30px;
    }

    @media (max-width: 991px) {
      font-size: 16px;
      line-height: 20px;
    }
  }

  & ul {
    & li {
      font-weight: ${(props) => props.theme.font.weight.regular};
    }
  }

  & table {
    & tbody {
      & tr {
        & td {
          border: 1px solid ${(props) => props.theme.color.dark};
          padding: 10px;

          &:first-of-type {
            font-weight: ${(props) => props.theme.font.weight.bold};
            font-family: ${(props) => props.theme.font.family.secondary};
            text-transform: uppercase;
            vertical-align: top;
          }
        }
      }
    }
  }

  & p {
    font-weight: ${(props) => props.theme.font.weight.regular};
    line-height: 30px;

    &:last-child {
      margin-bottom: 0;
    }
    b,
    strong {
      font-size: 20px;
      font-weight: ${(props) => props.theme.font.weight.bold};
    }

    @media screen and (max-width: 991px) {
      line-height: 24px;
    }
  }

  & a {
  }

  & img {
  }

  /*
    WP WYSIWYG
  */
  .alignright {
    float: right;
    text-align: right;
  }

  .aligncenter {
    display: block;
    margin: 0 auto;
    text-align: center;
  }

  .wp-caption {
    max-width: 100%;
  }

  .wp-caption-text {
    font-size: 12px;
    font-weight: 700;
    text-align: center;
  }
`

const capitalize = (s: string) => {
  if (typeof s !== 'string') return ''
  return s.charAt(0).toUpperCase() + s.slice(1)
}

const convertCSSKey = (key: string) => {
  const keySplit = key.split('-')
  let newKey = ''

  keySplit.forEach((value, index) => {
    let newValue = value

    if (index > 0) {
      newValue = capitalize(value)
    }

    newKey += newValue
  })

  return newKey
}

const convertCSS = (style: any) => {
  const result: any = []

  if (!style) {
    return result
  }

  const attributes = style.split(';')

  attributes.forEach((attribute: any) => {
    const { 0: key, 1: value } = attribute.split(':')

    result[convertCSSKey(key)] = value
  })

  return result
}

const findAndReplace = (content: any, settings: any) =>
  parse(content, {
    replace: (domNode: any) => {
      if (domNode.name === 'a' && domNode.attribs.class === 'button-primary') {
        return (
          <ButtonPrimary to={domNode.attribs.href}>
            {domToReact(domNode.children, {})}
          </ButtonPrimary>
        )
      }

      if (
        domNode.name === 'a' &&
        domNode.attribs.class === 'button-secondary'
      ) {
        return (
          <ButtonSecondary to={domNode.attribs.href}>
            {domToReact(domNode.children, {})}
          </ButtonSecondary>
        )
      }

      if (domNode.children && domNode.children.length > 0) {
        const html = renderToString(
          domToReact(domNode.children, {}) as DOMElement<any, any>
        )
        const htmlFindAndReplace = findAndReplace(html, settings)

        const attributes = domNode.attribs

        if (
          attributes.src &&
          attributes.src.indexOf(settings.sourceUrl) === -1
        ) {
          attributes.src = `${settings.sourceUrl}${attributes.src}`
        }

        if (attributes.href) {
          if (
            attributes.href.indexOf('www.') !== -1 ||
            attributes.href.indexOf('http:') !== -1 ||
            attributes.href.indexOf('https:') !== -1
          ) {
            attributes.target = '_blank'
          } else if (
            attributes.href.indexOf('/wp-content/') !== -1 &&
            attributes.href.indexOf(settings.sourceUrl) === -1
          ) {
            attributes.href = `${settings.sourceUrl}${attributes.href}`
          }
        }

        attributes.className = attributes.class

        delete attributes.class

        if (attributes.style) {
          attributes.style = convertCSS(attributes.style)
        }

        const Comp = domNode.name

        return (
          <Comp
            src={attributes.src}
            className={attributes.className}
            style={attributes.style}
            id={attributes.id}
            href={attributes.href}
            target={attributes.target}
          >
            {htmlFindAndReplace}
          </Comp>
        )
      }

      if (domNode.name === 'img') {
        const attributes = domNode.attribs

        if (
          domNode.attribs.src.indexOf('/wp-content/') !== -1 &&
          domNode.attribs.src.indexOf(settings.sourceUrl) === -1
        ) {
          attributes.src = `${settings.sourceUrl}${domNode.attribs.src}`
        }

        attributes.className = attributes.class

        delete attributes.class

        if (attributes.style) {
          attributes.style = convertCSS(attributes.style)
        }

        return (
          <img
            src={attributes.src}
            className={attributes.className}
            style={attributes.style}
            width={attributes.width}
            height={attributes.height}
            alt={attributes.alt}
          />
        )
      }

      if (domNode.name === 'a') {
        const attributes = domNode.attribs

        if (attributes.style) {
          delete attributes.style
        }

        if (
          attributes.href.indexOf('www.') !== -1 ||
          attributes.href.indexOf('http:') !== -1 ||
          attributes.href.indexOf('https:') !== -1
        ) {
          return (
            // eslint-disable-next-line react/jsx-props-no-spreading
            <a {...attributes} target="_blank">
              {domToReact(domNode.children, {})}
            </a>
          )
        }

        return (
          // eslint-disable-next-line react/jsx-props-no-spreading
          <CustomLink to={attributes.href} {...attributes}>
            {domToReact(domNode.children, {})}
          </CustomLink>
        )
      }

      return null
    },
  })

interface ParseContentProps {
  content: any
  className?: string
  isLightHs?: boolean
}

const ParseContent: React.FC<ParseContentProps> = ({
  content,
  className = '',
  isLightHs = false,
}) => {
  // eslint-disable-next-line
  const { site } = useStaticQuery<GatsbyTypes.ParseContentQueryQuery>(graphql`
    query ParseContentQuery {
      site {
        siteMetadata {
          siteWpUrl
        }
      }
    }
  `)

  let sourceUrl = ''

  if (site && site.siteMetadata && site.siteMetadata.siteWpUrl) {
    sourceUrl = site.siteMetadata.siteWpUrl
  }

  if (!content) {
    return null
  }

  return (
    <Content className={className} isLightHs={isLightHs}>
      {findAndReplace(content, { sourceUrl })}
    </Content>
  )
}

export default ParseContent
