import ParseContent from 'components/shared/ParseContent'
import React from 'react'

// Images
import Phone from 'img/call.inline.svg'

// Third Party
import styled from 'styled-components'

const Block = styled.div`
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);

  & svg {
    width: 37px;
    height: 37px;
    fill: #ffffff;
    margin-right: 1rem;
  }

  & a {
    color: ${(props) => props.theme.color.primary};
    font-weight: ${(props) => props.theme.font.weight.bold};

    &:hover {
      text-decoration: underline;
    }
  }
`

interface MoreInfoProps {
  // eslint-disable-next-line
  fields: GatsbyTypes.WpPage_Flexcontent_Flex_Cta
}

const MoreInfo: React.FC<MoreInfoProps> = ({ fields }) => (
  <section>
    <Block className="p-4 bg-dark text-light">
      <ParseContent content={fields.description} />
      <div className="d-flex align-items-center pt-3">
        <a href={fields.phone?.url}>
          <Phone />
          {fields.phone?.title}
        </a>
      </div>
    </Block>
  </section>
)

export default MoreInfo
