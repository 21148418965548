import React from 'react'

// Third Party
import styled from 'styled-components'

const StyledTitlePrimary = styled.div`
  position: relative;
  display: inline-flex;

  @media (min-width: 992px) {
    font-size: 65px;
    line-height: 65px;
  }

  @media (max-width: 991px) {
    font-size: 40px;
    line-height: 40px;
  }

  &:before {
    content: '';
    position: absolute;
    height: 5px;
    background-color: ${(props) => props.theme.color.primary};
    width: 2000px;
    left: 105%;
    bottom: 15px;
  }
`

interface TitlePrimaryProps {
  content: string
}

const TitlePrimary: React.FC<TitlePrimaryProps> = ({ content }) => (
  <StyledTitlePrimary className="font-family-secondary">
    {content}
  </StyledTitlePrimary>
)

export default TitlePrimary
