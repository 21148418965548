import React from 'react'

// Components
import GravityForm from 'components/shared/GravityForm'
import ParseContent from 'components/shared/ParseContent'

// Third Party
import styled from 'styled-components'

interface FormProps {
  // eslint-disable-next-line
  fields: GatsbyTypes.WpPage_Flexcontent_Flex_Form
  location?: any
}

const Block = styled.div`
  border: 2px solid ${(props) => props.theme.color.primary};
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.4);

  & h1,
  h2,
  h3,
  h4 {
    padding-bottom: 30px;
    font-family: ${(props) => props.theme.font.family.secondary};
    font-size: 30px;
  }
`

const Form: React.FC<FormProps> = ({ fields }) => (
  <section>
    <Block className="bg-light p-lg-5 p-4">
      <ParseContent content={fields.description} />
      <GravityForm id={Number(fields.formId)} />
    </Block>
  </section>
)

export default Form
