import React from 'react'

// Components
import FlexContentHandler from 'components/shared/FlexContentHandler'
import ParseContent from 'components/shared/ParseContent'
// import Share from 'components/elements/Share'

// Third Party
import styled, { css } from 'styled-components'

const TextBlock = styled.div<{ background: string }>`
  ${(props) =>
    props.background === 'transparent' &&
    css`
      background-color: transparent;
      color: ${props.theme.color.light};
    `}

  ${(props) =>
    props.background === 'white' &&
    css`
      background-color: ${props.theme.color.light};
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
    `}

  & li {
    line-height: 30px;
  }

  & p {
    line-height: 30px;

    & b,
    strong {
      font-size: 16px;
    }
  }
`

// const ShareWrapper = styled.div`
//   & h4 {
//     color: ${(props) => props.theme.color.primary};
//     font-weight: ${(props) => props.theme.font.weight.bold};
//     font-size: 16px;
//   }
// `

const Fragments = styled.div`
  & section + section {
    margin-top: 30px;
  }
`

interface TextWithFragmentProps {
  // eslint-disable-next-line
  fields: GatsbyTypes.WpPage_Flexcontent_Flex_Text
  location: any
}

const TextWithFragment: React.FC<TextWithFragmentProps> = ({
  fields,
  location,
}) => (
  <section>
    <div className="container pb-5">
      <div className="row">
        <div className={`col-lg-${fields.width?.split('/')[0]}`}>
          <TextBlock
            background={fields.background || 'white'}
            className="p-lg-5 p-4"
          >
            <ParseContent content={fields.description} />
            {/* <div className="row pt-5">
              <ShareWrapper className="col-lg-6">
                <h4>Deel dit verhaal</h4>
                <Share location={location} />
              </ShareWrapper>
            </div> */}
          </TextBlock>
        </div>
        <Fragments className={`col-lg-${fields.width?.split('/')[1]}`}>
          <div className="pt-lg-0 pt-4">
            <FlexContentHandler
              prefix="fragment_FlexFragment"
              fields={fields.element?.flex_fragment}
              location={location}
            />
          </div>
        </Fragments>
      </div>
    </div>
  </section>
)

export default TextWithFragment
