import React from 'react'
import { GatsbyImage } from 'gatsby-plugin-image'

interface ImageProps {
  className?: string
  image: any
  alt?: string
}

const Image: React.FC<ImageProps> = ({ className = '', image, alt = '' }) => {
  if (!image?.localFile?.childImageSharp?.gatsbyImageData) {
    return null
  }

  return (
    <GatsbyImage
      className={className}
      image={image?.localFile?.childImageSharp?.gatsbyImageData}
      alt={alt}
    />
  )
}

export default Image
