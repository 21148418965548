import React from 'react'

// Components
import ParseContent from 'components/shared/ParseContent'

// Third Party
import styled from 'styled-components'

interface BannerNoBackgroundProps {
  // eslint-disable-next-line
  fields: GatsbyTypes.WpPost_Flexcontent_Flex_Banner
  location?: any
}

const StyledBannerNoBackground = styled.section`
  margin-bottom: -300px;
  position: relative;
  z-index: -1;

  @media screen and (max-width: 991px) {
    margin-bottom: 0;
  }
`

const BannerWrapper = styled.div`
  background-color: ${(props) => props.theme.color.secondary};
  position: relative;
  height: 476px;
  /* max-width: 2000px; */
  clip-path: polygon(0 0, 100% 0, 100% 63%, 0% 100%);

  @media screen and (max-width: 991px) {
    height: fit-content;
  }
`

const Content = styled(ParseContent)`
  color: ${(props) => props.theme.color.dark};
  position: relative;
  z-index: 3;
  display: flex;
  justify-content: space-between;

  & h2 {
    margin-bottom: 100px;
    position: relative;

    &:before {
      content: '';
      position: absolute;
      height: 5px;
      width: 2230px;
      background-color: ${(props) => props.theme.color.primary};
      bottom: -50px;
      left: -1000px;
    }
  }

  & p {
    max-width: 550px;
  }

  @media screen and (max-width: 991px) {
    display: block;

    & h1,
    h2,
    h3 {
      margin-bottom: 50px;

      &:before {
        bottom: -25px;
      }
    }

    & p {
      padding-right: 0;
    }
  }
`

const BannerNoBackground: React.FC<BannerNoBackgroundProps> = ({ fields }) => (
  <StyledBannerNoBackground className="position-relative">
    <BannerWrapper>
      <div className="h-100 py-5 container">
        <div className="w-100 row pb-lg-0 pb-5">
          <div className="col-lg-12">
            <Content content={fields.description} />
          </div>
        </div>
      </div>
    </BannerWrapper>
  </StyledBannerNoBackground>
)

export default BannerNoBackground
