/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/no-array-index-key */
import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { useStaticQuery, graphql } from 'gatsby'

// Libraries
import Select from 'react-select'

// Components
import Content from 'components/shared/Content'
import Loading from 'components/shared/Loading'
import ButtonPrimary from 'components/elements/Button/ButtonPrimary'

const StyledForm = styled.form`
  position: relative;

  .hidden {
    display: none;
  }
`

const ConfirmationMessage = styled(Content)`
  font-size: 28px;
  font-weight: ${(props) => props.theme.font.weight.regular};
  font-family: ${(props) => props.theme.font.family.secondary};
  text-align: center;
`

const Label = styled.label`
  display: block;
  font-size: 16px;
`

const Mandatory = styled.span`
  font-size: ${(props) => props.theme.font.size[20]};
  color: ${(props) => props.theme.color.tertiary};
  margin-left: 5px;
`

interface GravityFormProps {
  id: number
  className?: string
  title?: string
}

const GravityForm = ({ className = '', id, title }: GravityFormProps) => {
  const {
    allGfForm: { edges: gravityData },
    // eslint-disable-next-line
  } = useStaticQuery<GatsbyTypes.GravityFormsQueryQuery>(graphql`
    query GravityFormsQuery {
      allGfForm {
        edges {
          node {
            formId
            slug
            apiURL
            descriptionPlacement
            formFields {
              id
              label
              labelPlacement
              description
              descriptionPlacement
              type
              choices
              content
              errorMessage
              inputMaskValue
              isRequired
              visibility
              cssClass
              placeholder
              size
              defaultValue
              maxLength
            }
            button {
              text
            }
            confirmations {
              message
            }
          }
        }
      }
    }
  `)

  const { node: gfForm } = gravityData.filter(
    ({ node }: any) => node.formId === id
  )[0]

  const [fields, setFields] = useState<any>({})
  const [form, setForm] = useState<any>(gfForm)
  const [status, setStatus] = useState<string>('')
  const [message, setMessage] = useState<string>('')

  useEffect(() => {
    const tempForm = form

    // add submit button as a field
    if (
      tempForm.formFields.filter((item: any) => item.type === 'submit')
        .length === 0
    ) {
      tempForm.formFields = [
        // @ts-ignore
        ...tempForm.formFields,
        {
          formId: id,
          type: 'submit',
          text: tempForm.button.text,
        },
      ]
    }

    if (typeof title !== 'undefined') {
      setFields({
        ...fields,
        input_6: title,
      })
    }

    setForm({
      ...form,
      ...tempForm,
    })
  }, [])

  async function handleOnSubmit(event: any) {
    event.preventDefault()

    if (status === 'processing') {
      return
    }

    setStatus('processing')

    try {
      const formData = new FormData()

      Object.keys(fields).forEach((key: string) => {
        formData.append(key, fields[key])
      })

      const request = await fetch(`${form.apiURL}/submissions`, {
        method: 'POST',
        body: formData,
      })

      const response = await request.json()
      if (response.is_valid === true) {
        setStatus('done')

        setMessage(response.confirmation_message)
      } else {
        setStatus('error')
      }
    } catch (error) {
      setStatus('error')
    }
  }

  function handleFieldChange(event: any) {
    let { value } = event.target || event.value

    if (event.target) {
      if (event.target.type === 'checkbox') {
        value = event.target.checked ? event.target.value : ''
      }

      setFields({
        ...fields,
        [event.target.name]: value,
      })
    } else {
      setFields({
        ...fields,
        [event.name]: event.value,
      })
    }
  }

  if (status === 'done') {
    return <ConfirmationMessage className="" content={message} />
  }

  if (form.formFields) {
    return (
      <StyledForm
        id={`form_${gfForm.formId}`}
        className={className}
        method="post"
        onSubmit={handleOnSubmit}
      >
        {status === 'processing' && <Loading />}
        {form.formFields &&
          form.formFields.map((field: any, key: number) => {
            if (Array.isArray(field)) {
              return (
                <div key={key}>
                  {field.map((item, index) => (
                    <FormField
                      key={index}
                      field={item}
                      fields={fields}
                      onChange={handleFieldChange}
                    />
                  ))}
                </div>
              )
            }

            return (
              <div key={key}>
                <FormField
                  field={field}
                  fields={fields}
                  onChange={handleFieldChange}
                />
              </div>
            )
          })}
      </StyledForm>
    )
  }

  return null
}

const StyledTextField = styled.div`
  & input {
    width: 100%;
    border: 1px solid rgba(16, 16, 16, 0.22);
    border-radius: 7px;
    padding: 10px 15px;
  }
`

const TextField = ({
  value,
  onChange,
  field: { id, type, label, labelPlacement, placeholder, isRequired, cssClass },
}: any) => (
  <>
    {cssClass !== 'hidden' && (
      <StyledTextField className="form-group">
        {labelPlacement !== 'hidden_label' && (
          <Label htmlFor={`input_${id}`}>
            {label}
            {isRequired && <Mandatory>*</Mandatory>}
          </Label>
        )}
        <input
          value={value}
          onChange={onChange}
          type="text"
          id={`input_${id}`}
          className={`${type} ${cssClass !== undefined ? cssClass : ''}`}
          name={`input_${id}`}
          required={isRequired}
          placeholder={placeholder}
        />
      </StyledTextField>
    )}
  </>
)

const StyledEmailField = styled.div`
  & input {
    width: 100%;
    border: 1px solid rgba(16, 16, 16, 0.22);
    border-radius: 7px;
    padding: 10px 15px;
  }
`

const EmailField = ({
  value,
  onChange,
  field: { id, type, label, labelPlacement, placeholder, isRequired, cssClass },
}: any) => (
  <StyledEmailField className="form-group">
    {labelPlacement !== 'hidden_label' && (
      <Label htmlFor={`input_${id}`}>
        {label}
        {isRequired && <Mandatory>*</Mandatory>}
      </Label>
    )}
    <input
      value={value}
      onChange={onChange}
      type="email"
      id={`input_${id}`}
      className={`${type} ${cssClass !== undefined ? cssClass : ''}`}
      name={`input_${id}`}
      required={isRequired}
      placeholder={placeholder}
    />
  </StyledEmailField>
)

const StyledPhoneField = styled.div`
  & input {
    width: 100%;
    border: 1px solid rgba(16, 16, 16, 0.22);
    border-radius: 7px;
    padding: 10px 15px;
  }
`

const PhoneField = ({
  value,
  onChange,
  field: { id, type, label, labelPlacement, placeholder, isRequired, cssClass },
}: any) => (
  <StyledPhoneField className="form-group">
    {labelPlacement !== 'hidden_label' && (
      <Label htmlFor={`input_${id}`}>
        {label}
        {isRequired && <Mandatory>*</Mandatory>}
      </Label>
    )}
    <input
      value={value}
      onChange={onChange}
      type="tel"
      id={`input_${id}`}
      className={`${type} ${cssClass !== undefined ? cssClass : ''}`}
      name={`input_${id}`}
      required={isRequired}
      placeholder={placeholder}
    />
  </StyledPhoneField>
)

const StyledTextareaField = styled.div`
  & textarea {
    width: 100%;
    border: 1px solid rgba(16, 16, 16, 0.22);
    border-radius: 7px;
    padding: 10px 15px;
    min-height: 100px;
  }
`

const TextAreaField = ({
  value,
  onChange,
  field: { id, type, label, labelPlacement, placeholder, isRequired, cssClass },
}: any) => (
  <StyledTextareaField className="form-group">
    {labelPlacement !== 'hidden_label' && (
      <Label htmlFor={`input_${id}`}>
        {label}
        {isRequired && <Mandatory>*</Mandatory>}
      </Label>
    )}
    <textarea
      value={value}
      onChange={onChange}
      id={`input_${id}`}
      className={`${type} ${cssClass !== undefined ? cssClass : ''}`}
      name={`input_${id}`}
      required={isRequired}
      placeholder={placeholder}
    />
  </StyledTextareaField>
)

const CheckboxField = ({
  value,
  onChange,
  field: { id, type, cssClass, choices },
}: any) => {
  const list = JSON.parse(choices)

  return (
    <div className="form-group">
      {list.map((checkbox: any, key: number) => (
        <div key={key} className="form-group__checkboxes">
          <input
            checked={value}
            onChange={onChange}
            type="checkbox"
            id={`input_${id}_${key + 1}`}
            className={`${type} ${cssClass !== undefined ? cssClass : ''}`}
            name={`input_${id}_${key + 1}`}
            value={checkbox.value}
          />
          <label
            htmlFor={`input_${id}_${key + 1}`}
            className="checkbox-content"
          >
            {checkbox.text}
          </label>
        </div>
      ))}
    </div>
  )
}

const StyledSelect = styled.div``

// Select or Dropdown
const SelectField = ({ field, onChange, value }: any) => {
  // Populate a options array
  const options: Array<any> = []
  JSON.parse(field.choices).forEach((choice: any) => {
    options.push({
      value: choice.value,
      label: choice.text,
      type: 'select',
      name: `input_${field.id}`,
    })
  })

  // Custom Select component
  const MySelect = (props: any) => (
    <Select
      {...props}
      onChange={props.onChange}
      options={props.options}
      placeholder={props.placeholder}
    />
  )

  return (
    <StyledSelect>
      <MySelect
        options={options}
        onChange={onChange}
        value={options.filter((option) => option.value === value)}
        placeholder={field.label}
        classNamePrefix="react-select"
      />
    </StyledSelect>
  )
}

const StyledButtonPrimary = styled(ButtonPrimary)`
  width: 100%;

  & a,
  button {
    padding: 10px;
    font-size: 18px;
  }
`

const SubmitButton = ({ field }: any) => (
  <div>
    <StyledButtonPrimary to="/" isCustom>
      <button type="submit">{field.text}</button>
    </StyledButtonPrimary>
  </div>
)

const StyledFileField = styled.div`
  & input {
    display: none;
  }
`

const CustomInput = styled.div`
  min-height: 52px;
  border: 1px solid rgba(16, 16, 16, 0.22);
  border-radius: 7px;
  padding: 0 15px;
`

const Box = styled.div`
  cursor: pointer;
  transition: all 0.3s ease;

  &:hover {
    background-color: ${(props) => props.theme.color.secondary} !important;
    color: ${(props) => props.theme.color.dark} !important;
  }
`

const FileField = ({
  value,
  onChange,
  field: { id, type, allowedExtensions, cssClass, placeholder },
}: any) => {
  function handleChange(file: any) {
    onChange({
      target: {
        name: `input_${id}`,
        value: file,
      },
    })
  }

  function handleFileUpload(e: any) {
    const {
      target: { files },
    } = e

    for (let i = 0; i < files.length; i += 1) {
      handleChange(files[i])
    }
  }

  function handleFileDrop(e: any) {
    e.preventDefault()

    if (e.dataTransfer.items) {
      // Use DataTransferItemList interface to access the file(s)
      for (let i = 0; i < e.dataTransfer.items.length; i += 1) {
        // If dropped items aren't files, reject them
        if (e.dataTransfer.items[i].kind === 'file') {
          handleChange(e.dataTransfer.items[i].getAsFile())
        }
      }
    } else {
      // Use DataTransfer interface to access the file(s)
      for (let i = 0; i < e.dataTransfer.files.length; i += 1) {
        handleChange(e.dataTransfer.files[i])
      }
    }
  }

  return (
    <StyledFileField
      className="form-upload"
      onDrop={handleFileDrop}
      onDragOver={(e) => e.preventDefault()}
    >
      <Label htmlFor={`input_${id}`}>
        Upload CV<Mandatory>*</Mandatory>
        <CustomInput className="mt-2 d-flex align-items-center">
          <Box className="bg-dark text-light px-3 py-2 font-size-12">
            Bestand kiezen
          </Box>
          {value && <div className="ml-3">{value.name}</div>}
        </CustomInput>
      </Label>
      <input
        type="file"
        onChange={handleFileUpload}
        id={`input_${id}`}
        className={`${type} ${cssClass !== undefined ? cssClass : ''}`}
        name={`input_${id}`}
        placeholder={placeholder}
        accept={allowedExtensions}
      />
    </StyledFileField>
  )
}

export const FormField = ({ field, fields, onChange }: any) => (
  <>
    {field.type === 'text' && (
      <TextField
        onChange={onChange}
        value={fields[`input_${field.id}`]}
        field={field}
      />
    )}
    {field.type === 'email' && (
      <EmailField
        onChange={onChange}
        value={fields[`input_${field.id}`]}
        field={field}
      />
    )}
    {field.type === 'phone' && (
      <PhoneField
        onChange={onChange}
        value={fields[`input_${field.id}`]}
        field={field}
      />
    )}
    {field.type === 'textarea' && (
      <TextAreaField
        onChange={onChange}
        value={fields[`input_${field.id}`]}
        field={field}
      />
    )}
    {field.type === 'checkbox' && (
      <CheckboxField
        onChange={onChange}
        value={fields[`input_${field.id}`]}
        field={field}
      />
    )}
    {field.type === 'select' && (
      <SelectField
        onChange={onChange}
        value={fields[`input_${field.id}`]}
        field={field}
      />
    )}
    {field.type === 'fileupload' && (
      <FileField
        onChange={onChange}
        value={fields[`input_${field.id}`]}
        field={field}
      />
    )}
    {field.type === 'submit' && <SubmitButton field={field} />}
  </>
)

export default GravityForm
