import ParseContent from 'components/shared/ParseContent'
import React from 'react'

// Components
import Video from 'components/elements/Modal/Video'

interface TextWithVideoProps {
  // eslint-disable-next-line
  fields: GatsbyTypes.WpPage_Flexcontent_Flex_Text
}

const TextWithVideo: React.FC<TextWithVideoProps> = ({ fields }) => (
  <section>
    <div className="container py-lg-5 py-3">
      <div className="row">
        <div className="col-lg-6 pb-lg-0 pb-4">
          <ParseContent content={fields.description} />
        </div>
        <div className="col-lg-6">
          <Video fields={fields} />
        </div>
      </div>
    </div>
  </section>
)

export default TextWithVideo
