/* eslint-disable no-underscore-dangle */
import React from 'react'

// Lazy loading
// import loadable from '@loadable/component'
import BannerShell from 'components/flex/Banner/BannerShell'
import VacatureShell from 'components/flex/Vacature/VacatureShell'
import TextShell from 'components/flex/Text/TextShell'
import CTAShell from 'components/flex/CTA/CTAShell'
import FormShell from 'components/flex/Form/FormShell'
import StoryShell from 'components/flex/Story/StoryShell'
import Blog from 'components/flex/Blog'
import Fragment from 'components/flex/Fragment'
import Departments from 'components/flex/Department/Departments'
import ShowcaseShell from 'components/flex/Showcase/ShowcaseShell'

// Elements
// import Form from 'components/flex/Form'
// import Text from 'components/flex/Text'
// import Fragment from 'components/flex/Fragment'

interface FlexContentProps {
  prefix?: string
  fields: any
  location?: any
  title?: string
}

interface FlexerProps {
  [key: string]: any
}

interface ComponentProps {
  fields: any
  location?: any
  title?: string
}

const FlexContentHandler: React.FC<FlexContentProps> = ({
  prefix = 'page_Flexcontent',
  fields,
  location = {},
  title,
}) => {
  const flexers: FlexerProps = {
    // [`${prefix}_Flex_Banner`]: loadable(
    //   () => import('components/flex/Banner/BannerShell')
    // ),
    // [`${prefix}_Flex_Form`]: loadable(() => import('components/flex/Form')),
    // [`${prefix}_Flex_Text`]: loadable(() => import('components/flex/Text')),
    // [`${prefix}_Flex_Blog`]: loadable(() => import('components/flex/Blog')),
    // [`${prefix}_Flex_Fragment`]: loadable(
    //   () => import('components/flex/Fragment')
    // ),
    [`${prefix}_Flex_Banner`]: BannerShell,
    [`${prefix}_Flex_Vacatures`]: VacatureShell,
    [`${prefix}_Flex_Text`]: TextShell,
    [`${prefix}_Flex_Cta`]: CTAShell,
    [`${prefix}_Flex_Form`]: FormShell,
    [`${prefix}_Flex_Stories`]: StoryShell,
    [`${prefix}_Flex_Showcases`]: ShowcaseShell,
    [`${prefix}_Flex_Blog`]: Blog,
    [`${prefix}_Flex_Departments`]: Departments,
    [`${prefix}_Flex_Fragment`]: Fragment,
  }

  if (!fields.flex) {
    return null
  }

  return (
    <div>
      {fields.flex.map((section: any, index: number) => {
        if (!section.fieldGroupName || !flexers[section.fieldGroupName]) {
          return null
        }

        const Component: React.FC<ComponentProps> =
          flexers[section.fieldGroupName]

        return (
          <Component
            // eslint-disable-next-line react/no-array-index-key
            key={`${location ? location.pathname : 'flex'}-${index}`}
            fields={section}
            location={location}
            title={title}
          />
        )
      })}
    </div>
  )
}

export default FlexContentHandler
