import React from 'react'

// Third Party
import styled from 'styled-components'

const StyledTitleSecondary = styled.div`
  font-family: ${(props) => props.theme.font.family.secondary};
  text-align: center;

  @media (min-width: 992px) {
    font-size: 65px;
    line-height: 65px;
  }

  @media (max-width: 991px) {
    font-size: 40px;
    line-height: 40px;
  }
`

interface TitleSecondaryProps {
  content: string
}

const TitleSecondary: React.FC<TitleSecondaryProps> = ({ content }) => (
  <StyledTitleSecondary>{content}</StyledTitleSecondary>
)

export default TitleSecondary
