import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'

// Components
import TitleSecondary from 'components/elements/TitleSecondary'
import DepartmentPreview from 'components/flex/Department/DepartmentPreview'

interface DepartmentsProps {
  // eslint-disable-next-line
  fields: GatsbyTypes.WpPage_Flexcontent_Flex_Departments
}

const Departments: React.FC<DepartmentsProps> = () => {
  // eslint-disable-next-line
  const query = useStaticQuery<GatsbyTypes.departmentsQuery>(graphql`
    query departments {
      allWpPage(filter: { parentDatabaseId: { eq: 231 } }) {
        nodes {
          ...generalPageFragment
        }
      }

      allWpVacature {
        nodes {
          ...generalVacatureFragment
        }
      }
    }
  `)

  return (
    <section>
      <div className="container py-5">
        <TitleSecondary content="AFDELINGEN" />
        <div className="row justify-content-center">
          {query.allWpPage.nodes.map((type) => (
            <div className="col-md-6 col-lg-4">
              <DepartmentPreview
                // @ts-ignore
                vacancies={query.allWpVacature.nodes}
                // @ts-ignore
                fields={type}
              />
            </div>
          ))}
        </div>
      </div>
    </section>
  )
}

export default Departments
