import React, { createContext, useState, useEffect } from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import styled from 'styled-components'

export const BlogContext = createContext({})

export const BlogProvider = BlogContext.Provider
export const BlogConsumer = BlogContext.Consumer

interface BlogikProps {
  settings: {
    id: string
    type: string
    postIds?: Array<number>
    limit?: number
    usePagination?: boolean
    presetFilters?: Array<string>
  }
  children: any
}

interface ContextProps {
  isSelected?: any
  setSelectedFilters?: any
  decrementOffset?: any
  incrementOffset?: any
  unfilteredPosts?: any
  limit?: any
  offset?: number
  setOffset?: any
}

const Blogik: React.FC<BlogikProps> = ({ settings: options, children }) => {
  const settings = {
    ...{
      id: 'blog',
      type: 'blog',
      postIds: [],
      presetFilters: [],
      limit: 6,
      usePagination: false,
    },
    ...options,
  }

  const {
    allWpPost: { edges: postEdges },
    allWpCategory: { edges: categoryEdges },
    allWpVacature: { edges: vacatureEdges },
    allWpVacatureType: { edges: vacatureTypeEdges },
    // eslint-disable-next-line
  } = useStaticQuery<GatsbyTypes.blogikQueryQuery>(graphql`
    query blogikQuery {
      allWpPost(sort: { order: ASC, fields: date }) {
        edges {
          node {
            ...generalPostFragment
          }
        }
      }
      allWpCategory(filter: { name: { nin: "Uncategorized" } }) {
        edges {
          node {
            id
            name
          }
        }
      }
      allWpVacature(sort: { fields: date, order: DESC }) {
        edges {
          node {
            ...generalVacatureFragment
          }
        }
      }
      allWpVacatureType {
        edges {
          node {
            id
            name
          }
        }
      }
    }
  `)

  const edges = {
    blog: postEdges,
    vacature: vacatureEdges,
  }[settings.type]

  const catEdges = {
    blog: categoryEdges,
    vacature: vacatureTypeEdges,
  }[settings.type]

  const [selectedFilters, setSelectedFilters] = useState<Array<any>>([])
  const [offset, setOffset] = useState<number>(1)

  const setSelectedFiltersLocal = (filter: any) => {
    // @ts-ignore
    const newSelectedFilters = [...selectedFilters]

    const filterIndex: number = newSelectedFilters.indexOf(filter)

    if (filterIndex === -1) {
      newSelectedFilters.push(filter)
    } else {
      newSelectedFilters.splice(filterIndex, 1)
    }

    if (typeof localStorage !== 'undefined') {
      localStorage.setItem(
        `${settings.id}-filters`,
        JSON.stringify(newSelectedFilters)
      )
    }

    setSelectedFilters(newSelectedFilters)
  }

  const setOffsetLocal = (newOffset: any) => {
    // if (typeof localStorage !== 'undefined') {
    //   localStorage.setItem(`${settings.id}-offset`, newOffset)
    // }

    setOffset(newOffset)
  }

  const incrementOffset = () => {
    let newOffset: number = Number(offset)

    newOffset += 1

    setOffsetLocal(newOffset)
  }

  const decrementOffset = () => {
    let newOffset = Number(offset)

    newOffset -= 1

    setOffsetLocal(newOffset)
  }

  const isSelected = (filter: any) => selectedFilters.indexOf(filter) !== -1

  const filterPosts = (posts: any, { ids }: any) =>
    posts.filter(({ node }: any, index: number) => {
      let response = true

      if (
        settings.limit !== null &&
        (index + 1 > offset * settings.limit ||
          (settings.usePagination && offset > index + 1))
      ) {
        return false
      }

      if (ids?.length > 0 && ids.indexOf(node.id) === -1) {
        return false
      }

      if (selectedFilters.length > 0) {
        response = false

        if (node.categories) {
          node.categories.nodes.forEach((category: any) => {
            if (selectedFilters.indexOf(category.id) !== -1) {
              response = true
            }
          })
        }

        if (node.vacatureTypes) {
          node.vacatureTypes.nodes.forEach((type: any) => {
            if (selectedFilters.indexOf(type.id) !== -1) {
              response = true
            }
          })
        }
      }

      return response
    })

  useEffect(() => {
    if (settings.presetFilters.length !== 0) {
      if (typeof localStorage !== 'undefined') {
        localStorage.setItem(
          `${settings.id}-filters`,
          JSON.stringify(settings.presetFilters)
        )
      }

      setSelectedFilters(settings.presetFilters)
    }

    if (typeof localStorage !== 'undefined') {
      const storageSelectedFilters = localStorage.getItem(
        `${settings.id}-filters`
      )
      // const storageOffset: any = localStorage.getItem(`${settings.id}-offset`)

      if (storageSelectedFilters) {
        setSelectedFilters(JSON.parse(storageSelectedFilters))
      }

      // if (storageOffset) {
      //   setOffset(storageOffset)
      // }
    }
  }, [])

  const posts = filterPosts(edges, {
    ids: settings.postIds,
  })

  const maxPosts =
    settings.postIds?.length > 0 ? settings.postIds.length : edges?.length

  return (
    <BlogProvider
      value={{
        setSelectedFilters: setSelectedFiltersLocal,
        selectedFilters,
        isSelected,
        setOffset: setOffsetLocal,
        incrementOffset,
        decrementOffset,
        offset,
        limit: settings.limit,
        filterPosts,
        unfilteredPosts: edges,
        posts,
        categories: catEdges,
        hasPosts: posts.length > 0,
        showMoreButton:
          (maxPosts || 0) > posts.length &&
          offset * settings.limit < (maxPosts || 0),
        showLessButton: offset > 1,
      }}
    >
      {children}
    </BlogProvider>
  )
}

const Button = styled.button`
  outline: none;
  padding: 0;

  &:focus {
    outline: none;
  }
`

interface BlogFilterProps {
  id: string
  children: any
  className?: string
}

export const BlogFilter: React.FC<BlogFilterProps> = ({
  className = '',
  children,
  id,
}) => (
  <BlogConsumer>
    {(context: ContextProps) => (
      <Button
        className={className}
        role="button"
        data-active={context.isSelected(id) ? 1 : 0}
        onClick={() => {
          context.setSelectedFilters(id)
        }}
        tabIndex={0}
      >
        {children}
      </Button>
    )}
  </BlogConsumer>
)

interface BlogButtonProps {
  increment?: boolean
  children: any
  className?: string
}

export const BlogButton: React.FC<BlogButtonProps> = ({
  className,
  children,
  increment = true,
}) => (
  <BlogConsumer>
    {(context: ContextProps) => (
      <Button
        className={className}
        type="button"
        onClick={() => {
          if (increment) {
            context.incrementOffset()
          } else {
            context.decrementOffset()
          }
        }}
        tabIndex={0}
      >
        {children}
      </Button>
    )}
  </BlogConsumer>
)

interface BlogPaginationProps {
  className?: string
}

export const BlogPagination: React.FC<BlogPaginationProps> = ({
  className = '',
}) => (
  <BlogConsumer>
    {(context: ContextProps) => {
      const paginations: number = context.unfilteredPosts.length / context.limit
      const pages = []

      for (let i = 0; i < paginations; i += 1) {
        pages.push(i + 1)
      }

      return (
        <div className={className}>
          {pages.map((page: number) => (
            <Button
              key={page}
              role="button"
              data-active={Number(page) === Number(context.offset) ? 1 : 0}
              onClick={() => {
                context.setOffset(page)
              }}
              tabIndex={0}
            >
              {page}
            </Button>
          ))}
        </div>
      )
    }}
  </BlogConsumer>
)

export default Blogik
