import React from 'react'

// Components
import Image from 'components/shared/Image'
import ParseContent from 'components/shared/ParseContent'

interface TextWithImageProps {
  // eslint-disable-next-line
  fields: GatsbyTypes.WpPage_Flexcontent_Flex_Text
}

const TextWithImage: React.FC<TextWithImageProps> = ({ fields }) => (
  <section>
    <div className="container py-lg-5 py-3">
      <div className="row">
        {fields.position === 'left' && (
          <div className={`col-lg-${fields.width?.split('/')[0]}`}>
            <Image image={fields.image} alt="" />
          </div>
        )}
        <div
          className={`col-lg-${
            fields.width?.split('/')[fields.position === 'left' ? 1 : 0]
          }`}
        >
          <div className="h-100 d-flex align-items-center">
            <ParseContent content={fields.description} />
          </div>
        </div>
        {fields.position === 'right' && (
          <div className={`col-lg-${fields.width?.split('/')[1]}`}>
            <Image image={fields.image} alt="" />
          </div>
        )}
      </div>
    </div>
  </section>
)

export default TextWithImage
