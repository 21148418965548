import Preview from 'components/elements/Story/Preview'
import React from 'react'

interface SelectionStoriesProps {
  // eslint-disable-next-line
  fields: GatsbyTypes.WpPage_Flexcontent_Flex_Stories
}

const SelectionStories: React.FC<SelectionStoriesProps> = ({ fields }) => {
  if (!fields.story![0]) {
    return null
  }

  return (
    <section>
      <div className="py-5">
        <Preview fields={fields.story![0]} />
      </div>
    </section>
  )
}

export default SelectionStories
