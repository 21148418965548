import ParseContent from 'components/shared/ParseContent'
import React from 'react'

// Third Party
import styled from 'styled-components'

const StyledHeader = styled.div`
  & h1,
  h2,
  h3,
  h4,
  h5 {
    position: relative;
    display: inline-flex;

    &:before {
      content: '';
      position: absolute;
      height: 5px;
      background-color: ${(props) => props.theme.color.primary};
      width: 2000px;
      left: 120%;
      top: 55px;
    }
  }
`

interface HeaderProps {
  // eslint-disable-next-line
  fields: GatsbyTypes.WpPage_Flexcontent_Flex_Text
}

const Header: React.FC<HeaderProps> = ({ fields }) => (
  <section>
    <div className="container pt-lg-5 pt-4 pb-lg-4 pb-3">
      <StyledHeader>
        <ParseContent content={fields.description} />
      </StyledHeader>
    </div>
  </section>
)

export default Header
