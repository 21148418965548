import React from 'react'

// Components
import AllShowcases from 'components/flex/Showcase/AllShowcases'

interface ShowcaseShellProps {
  fields: any
  location?: any
}

interface showcasesProps {
  [key: string]: any
}

const ShowcaseShell: React.FC<ShowcaseShellProps> = ({
  fields,
  location = {},
}) => {
  console.log(fields)
  const showcases: showcasesProps = {
    all: AllShowcases,
  }

  if (!fields.styleType || !showcases[fields.styleType]) {
    return null
  }

  const Component = showcases[fields.styleType]

  return (
    <Component
      key={`${location ? location.pathname : 'flex'}`}
      fields={fields}
      location={location}
    />
  )
}

export default ShowcaseShell
