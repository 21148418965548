import React from 'react'

import VideoModal from 'components/elements/Modal/Video'

interface VideoProps {
  // eslint-disable-next-line
  fields: GatsbyTypes.WpPage_Flexcontent_Flex_Text
}

const Video: React.FC<VideoProps> = ({ fields }) => (
  <section>
    <div className="container py-5">
      <div className="d-flex justify-content-center">
        <VideoModal fields={fields} />
      </div>
    </div>
  </section>
)

export default Video
