import React from 'react'

// Components
import FlexContentHandler from 'components/shared/FlexContentHandler'

interface FragmentProps {
  fields: any
  location?: any
  title?: string
}

const Fragment: React.FC<FragmentProps> = ({
  fields,
  location = {},
  title,
}) => (
  <FlexContentHandler
    prefix="fragment_FlexFragment"
    fields={fields.element.flex_fragment}
    location={location}
    title={title}
  />
)

export default Fragment
