import React from 'react'

// Components
import Image from 'components/shared/Image'
import CustomLink from 'components/shared/CustomLink'

// Third Party
import { motion } from 'framer-motion'
import styled from 'styled-components'

const StyledDepartmentPreview = styled.div`
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  overflow: hidden;
  min-height: 220px;

  @media screen and (max-width: 1200px) {
    min-height: 180px;
  }
`

const ImageWrapper = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: -2;
`

const Shadow = styled(motion.div)`
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: -1;
  background: rgb(255, 255, 255);
  background: -moz-linear-gradient(
    90deg,
    rgba(255, 255, 255, 0.2) 0%,
    rgba(255, 255, 255, 1) 75%,
    rgba(255, 255, 255, 1) 100%
  );
  background: -webkit-linear-gradient(
    90deg,
    rgba(255, 255, 255, 0.2) 0%,
    rgba(255, 255, 255, 1) 75%,
    rgba(255, 255, 255, 1) 100%
  );
  background: linear-gradient(
    90deg,
    rgba(255, 255, 255, 0.2) 0%,
    rgba(255, 255, 255, 1) 75%,
    rgba(255, 255, 255, 1) 100%
  );
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#ffffff",endColorstr="#ffffff",GradientType=1);
`

const Content = styled.div`
  position: absolute;
  bottom: 5px;
  right: 15px;

  & h2 {
    font-size: 20px;
    font-weight: ${(props) => props.theme.font.weight.bold};
    padding-right: 25px;
    position: relative;
    margin-bottom: 0;

    &:before {
      content: '';
      position: absolute;
      border-left: 15px solid ${(props) => props.theme.color.primary};
      border-top: 8px solid transparent;
      border-right: 15px solid transparent;
      border-bottom: 8px solid transparent;
      right: -15px;
      top: 5px;
    }
  }

  & p {
    text-align: right;
    padding-right: 25px;
    font-size: 16px;
    color: ${(props) => props.theme.color.primary};
  }
`

interface DepartmentPreviewProps {
  // eslint-disable-next-line
  fields: GatsbyTypes.WpPage
  // eslint-disable-next-line
  vacancies: Array<GatsbyTypes.WpVacature>
}

const DepartmentPreview: React.FC<DepartmentPreviewProps> = ({
  fields,
  vacancies,
}) => {
  const count = vacancies.filter((v) =>
    v.vacatureTypes?.nodes?.find((vt) => vt?.name === fields.title)
  ).length

  return (
    <motion.div
      key={fields.title}
      initial="init"
      whileHover="hover"
      whileTap="hover"
    >
      <CustomLink to={fields.uri}>
        <StyledDepartmentPreview className="mt-lg-5 mt-4 position-relative">
          <Shadow
            variants={{
              init: {
                background: `linear-gradient(
    90deg,
    rgba(255, 255, 255, 0.2) 0%,
    rgba(255, 255, 255, 1) 75%,
    rgba(255, 255, 255, 1) 100%
  )`,
              },
              hover: {
                background: `linear-gradient(
    90deg,
    rgba(255, 255, 255, 0.2) 0%,
    rgba(199, 28, 28, 1) 75%,
    rgba(199, 28, 28, 1) 100%
  )`,
              },
            }}
          />
          <ImageWrapper>
            <Image alt="" image={fields.flexPreview?.image} />
          </ImageWrapper>
          <Content>
            <motion.h2
              variants={{
                init: { color: '#101010' },
                hover: { color: '#FFFFFF' },
              }}
            >
              {fields.title}
            </motion.h2>
            <motion.p
              variants={{
                init: { color: '#d70926' },
                hover: { color: '#FFFFFF' },
              }}
            >
              {`${count} vacature${count === 1 ? '' : 's'}`}
            </motion.p>
          </Content>
        </StyledDepartmentPreview>
      </CustomLink>
    </motion.div>
  )
}

export default DepartmentPreview
