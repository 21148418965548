import React from 'react'

// Components
import Image from 'components/shared/Image'
import ButtonPrimary from 'components/elements/Button/ButtonPrimary'

// Images
import quote from 'img/quote.svg'

// Third Party
import styled from 'styled-components'
import ParseContent from 'components/shared/ParseContent'

const ImageWrapper = styled.div`
  position: absolute;
  height: 100%;
  width: 100%;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  z-index: -1;
  clip-path: polygon(0 30%, 100% 0, 100% 50%, 100% 70%, 0 100%, 0% 50%);
`

const StyledImage = styled(Image)`
  position: absolute !important;
  height: 100%;
  width: 100%;
`

const StyledPreview = styled.div`
  position: relative;
  max-width: 2000px;
`

const ContentWrapper = styled.div`
  & h2 {
    padding-bottom: 40px;
  }

  & p {
    font-weight: ${(props) => props.theme.font.weight.light};
  }

  & blockquote {
    position: relative;

    &:before {
      content: '';
      background-image: url(${quote});
      background-size: contain;
      background-repeat: no-repeat;
      position: absolute;
      width: 70px;
      height: 60px;
      top: -80px;
      left: 0;
      color: ${(props) => props.theme.color.primary};
      font-family: ${(props) => props.theme.font.family.secondary};
    }
  }
`

const TriImage = styled(Image)`
  position: absolute !important;
  top: -35%;
  right: 0;
  max-width: 520px;

  @media screen and (max-width: 991px) {
    display: none !important;
  }
`

const ButtonWrapper = styled.div`
  bottom: -30px;
`

interface PreviewProps {
  // eslint-disable-next-line
  fields: GatsbyTypes.WpStory
}

const Preview: React.FC<PreviewProps> = ({ fields }) => (
  <StyledPreview className="mb-5">
    <ImageWrapper>
      <StyledImage alt="" image={fields.storyPreview?.backgroundImage} />
    </ImageWrapper>
    <ContentWrapper className="container py-5 text-light">
      <div className="row py-5">
        <div className="col-lg-6 py-5 mt-5">
          <ParseContent content={fields.storyPreview?.description} />
        </div>
        <div className="col-lg-6">
          <TriImage image={fields.storyPreview?.image} alt="" />
        </div>
      </div>
    </ContentWrapper>
    <ButtonWrapper className="position-absolute w-100 justify-content-center d-flex">
      <ButtonPrimary to={fields.uri}>
        {fields.storyPreview?.readmore}
      </ButtonPrimary>
    </ButtonWrapper>
  </StyledPreview>
)

export default Preview
